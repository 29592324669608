import moment from 'moment';
import userAuthorizedAxios from './userAuthorizedAxios';
import { Subscription, UserSubscriptions } from '../../interfaces/Subscription/Subscription';

export default {
  bloodResultUpload: (): Promise<Subscription> => userAuthorizedAxios
    .get('/api/pages/subscriptions/bloodResultsUpload')
    .then((response) => response.data)
    .then((subscriptionData: {
      tag: string,
      name: string,
      length: number,
      hasAccessToFunctionality: boolean,
      accessExpiration: string | null,
      willBeProlongedAt: string | null,
      linkToManageSubscription: string | null,
      linkToPurchaseSubscription: string | null,
      cancelledAt: string | null,
      createdAt: string | null
    }) => ({
      ...subscriptionData,
      accessExpiration: subscriptionData.accessExpiration ? moment(subscriptionData.accessExpiration, 'YYYY-MM-DD') : null,
      willBeProlongedAt: subscriptionData.willBeProlongedAt ? moment(subscriptionData.willBeProlongedAt, 'YYYY-MM-DD') : null,
      cancelledAt: subscriptionData.cancelledAt ? moment(subscriptionData.cancelledAt, 'YYYY-MM-DD') : null,
      createdAt: subscriptionData.createdAt ? moment(subscriptionData.createdAt, 'YYYY-MM-DD') : null,
    })),

  getUserSubscriptions: (): Promise<UserSubscriptions> => userAuthorizedAxios.get('/api/customer/v1/subscriptions')
    .then((responseData) => responseData.data),
};
